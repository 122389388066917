import React from "react";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import CustomLink from "common/custom-link";
import buildImg from '../../../../assets/images/build-img.webp';
import ShimmerImage from "common/shimmer-image";
import styles from "./buildersQuiz.module.scss";
const BuildersQuiz = () => {
  const {
    locale
  } = useRouter();
  return <div className="px-4 xl:px-0" data-sentry-component="BuildersQuiz" data-sentry-source-file="BuildersQuiz.tsx">
      <div className={`${styles.buildersTop} flex items-center w-full bg-white mt-4 py-6 px-8 rounded-3xl gap-8`}>
        <div className="min-w-[171px]">
          <ShimmerImage alt="build-img" src={buildImg} title="build-img" data-sentry-element="ShimmerImage" data-sentry-source-file="BuildersQuiz.tsx" />
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-[#344054] text-[22px] font-semibold">
            {layoutLang[String(locale)]["builder_quiz_title"]}
          </p>
          <span className="text-[#667085] text-[17px]">
            {layoutLang[String(locale)]["builder_quiz_text"]}
          </span>
        </div>
        <div className="min-w-max px-[50px] flex items-center justify-center bg-[#00A389] rounded-lg cursor-pointer !text-white font-semibold text-base h-[43px]">
          <CustomLink href={"https://t.me/uysot_admin"} className="!text-white" data-sentry-element="CustomLink" data-sentry-source-file="BuildersQuiz.tsx">
            {layoutLang[String(locale)]["murojaat_qilish"]}
          </CustomLink>
        </div>
      </div>
      <div className={styles.buildersBottom}>
        <div className="flex flex-col bg-white mt-2 rounded-xl p-6">
          <p className="text-[#2C2C2C] text-[20px] font-medium">
            {layoutLang[String(locale)]["elon_joylash"]}
          </p>
          <div className="flex items-center justify-between gap-8">
            <div className="flex flex-col items-start gap-6">
              <span className="text-[#616161]">
                {layoutLang[String(locale)]["TJM_joylashtiring_xaridorlarni_jalb_qiling"]}
              </span>
              <div className="min-w-max px-4 flex items-center justify-center bg-[#00A389] rounded-[10px] cursor-pointer !text-white font-semibold text-sm h-[43px]">
                <CustomLink href={"https://t.me/uysot_admin"} className="!text-white" data-sentry-element="CustomLink" data-sentry-source-file="BuildersQuiz.tsx">
                  {layoutLang[String(locale)]["murojaat_qilish"]}
                </CustomLink>
              </div>
            </div>
            <div className="min-w-[124px]">
              <ShimmerImage alt="build-img" src={buildImg} title="build-img" data-sentry-element="ShimmerImage" data-sentry-source-file="BuildersQuiz.tsx" />
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default BuildersQuiz;