import { Checkbox, Dropdown, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { useTranslations } from "next-intl";
import ArrowBottom from "assets/icons/ArrowBottom";
import { hotSearchs, initialRooms } from "./mainFilterItems";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import { rootPaths } from "utils/constants/rootPaths";
import { BannerImageType, IBanner, IBannerImage } from "pages/app/utils/models/banner";
// import Banner from "./banner/Banner"
import CustomLink from "common/custom-link";
import FilterPriceModal from "common/filter-desktop/filter-price-modal/FilterPriceModal";
import { useAppSelector } from "hooks/reduxHooks";
import { useGetApartmentsCount } from "pages/main/services/queries";
import { USD } from "utils/constants/locales";
import SearchLocation from "../search-location/SearchLocation";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import { LOCATION } from "utils/constants/queryParamsKeys";
import MainLocationIcon from "assets/icons/MainLocationIcon";
interface IProps {
  banner?: IBanner<Record<BannerImageType, IBannerImage>> | undefined;
}
interface Data {
  [key: string]: any;
}
const MainFilter: React.FC<IProps> = () => {
  const {
    locale
  } = useRouter();
  const t = useTranslations("priceChange");
  const [roomsCount, setRoomsCount] = useState<string[]>([]);
  const filter = useAppSelector(state => state.favouritesReducer.filter);
  const currency = useAppSelector(state => state.localeReducer.currency);
  const usdValue = useAppSelector(state => state.localeReducer.usdValue);
  const mainRegionValue = useAppSelector(state => state.favouritesReducer?.location?.mainRegionValue);
  const regionUrl = mainRegionValue?.city ? mainRegionValue?.city?.url : mainRegionValue?.district ? mainRegionValue?.district?.city?.url : null;
  function getNumberValues(obj: Data): {
    [key: string]: number;
  } {
    const result: {
      [key: string]: number;
    } = {};
    for (const key in obj) {
      if (typeof obj[key] === 'number') {
        result[key] = obj[key];
      }
    }
    return result;
  }
  function cleanObject(obj: {
    [key: string]: any;
  }): {
    [key: string]: any;
  } {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (value === null || value === undefined || Array.isArray(value) && value.length === 0 || value === 0) {
        delete obj[key];
      }
    });
    return obj;
  }
  const handleChangeRooms = (value: string) => {
    if (roomsCount?.includes(value)) {
      const count = roomsCount?.filter(item => item !== value);
      setRoomsCount(count);
    } else {
      setRoomsCount(state => [...state, value]);
    }
  };
  function multiplyObjectValues(obj: Record<string, number>): Record<string, number> {
    const result: Record<string, number> = {};
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'number') {
        result[key] = currency === USD ? Math.floor(obj[key] * usdValue) : obj[key];
      }
    }
    return result;
  }
  const sendQuery = {
    ...multiplyObjectValues(getNumberValues(filter)),
    rooms_count: roomsCount,
    city_id: mainRegionValue?.city ? mainRegionValue?.city?.id : mainRegionValue?.district?.city?.id,
    district_id: mainRegionValue?.district?.id
  };
  const {
    data: count,
    isLoading
  } = useGetApartmentsCount(cleanObject(sendQuery));
  const mapQuery = regionUrl ? {
    ...sendQuery,
    [LOCATION]: regionUrl
  } : sendQuery;
  return <div className="md:flex hidden flex-col gap-2 w-full" data-sentry-component="MainFilter" data-sentry-source-file="MainFilter.tsx">
      <div className="flex items-center gap-5 h-[49px] w-full">
        <div className="flex items-center h-full bg-white rounded-xl w-[85%]">
          <div className="flex items-center flex-col px-4 w-[25%] border-r border-solid border-[#E9E9E9] h-full cursor-pointer relative">
            <Dropdown trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} overlay={<div className="flex flex-col bg-white w-full rounded-[4px]" style={{
            boxShadow: "0 4px 16px rgba(33,31,46,.12)"
          }}>
                  {initialRooms?.map(item => <Checkbox key={item?.key} value={item?.key} className="!m-0 px-2 py-1 rounded-[4px] hover:bg-[#daf3eb] transition-all duration-300" onChange={e => handleChangeRooms(e?.target?.value)}>
                      {item?.label}
                    </Checkbox>)}
                </div>} data-sentry-element="Dropdown" data-sentry-source-file="MainFilter.tsx">
              <div className="flex items-center justify-between w-full h-full">
                <span className="text-[#1D2939] font-medium">
                  {roomsCount?.length < 1 ? t("Xonalar_soni") : roomsCount?.sort((a, b) => Number(a) - Number(b))?.map(item => item === "0" ? t("Студия") : item)?.join(", ")}
                </span>
                <ArrowBottom data-sentry-element="ArrowBottom" data-sentry-source-file="MainFilter.tsx" />
              </div>
            </Dropdown>
          </div>
          <div className="flex items-center flex-col px-4 w-[32%] border-r border-solid border-[#E9E9E9] h-full cursor-pointer relative">
            <FilterPriceModal type="main" data-sentry-element="FilterPriceModal" data-sentry-source-file="MainFilter.tsx" />
          </div>
          <SearchLocation type="main" data-sentry-element="SearchLocation" data-sentry-source-file="MainFilter.tsx" />
        </div>
        <div className="flex items-center h-full gap-2">
          <div className="flex items-center gap-2 h-full">
            <CustomLink href={{
            pathname: `${rootPaths.MAP}`,
            query: mapQuery
          } as never} className="flex items-center h-full gap-2.5 rounded-xl px-4 cursor-pointer bg-white w-max" data-sentry-element="CustomLink" data-sentry-source-file="MainFilter.tsx">
              <MainLocationIcon data-sentry-element="MainLocationIcon" data-sentry-source-file="MainFilter.tsx" />
              <p className="text-[#2C2C2C] font-medium">{layoutLang[String(locale)]["Xaritada"]}</p>
            </CustomLink>
          </div>
          <CustomLink href={{
          pathname: `/${regionUrl ? regionUrl : DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`,
          query: sendQuery
        } as never} className="flex items-center justify-center gap-2 rounded-xl px-4 h-[50px] cursor-pointer bg-[#00A389] min-w-[125px] max-w-[125px]" data-sentry-element="CustomLink" data-sentry-source-file="MainFilter.tsx">
            <p className="text-white font-medium">{t("Izlash")}</p>
            {Object.keys(cleanObject(sendQuery))?.length > 0 && <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} className="!bg-[#00A389] text-white">
              <div className="flex items-center justify-center bg-[#F2F4F7] rounded-2xl h-6 px-2 !w-11 text-[#00A389]">{count}</div>
            </Spin>}
          </CustomLink>

        </div>
      </div>
      <div className="flex items-center gap-1 flex-wrap">
        {hotSearchs?.map(item => <CustomLink href={item?.link} className="rounded-2xl h-[33px] bg-[#E6E6E6] px-[15px] flex items-center text-[#2C2C2C] cursor-pointer select-none" key={item?.value?.uz}>
            {item?.value[locale as keyof typeof item.value]}
          </CustomLink>)}
      </div>
    </div>;
};
export default MainFilter;