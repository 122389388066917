import { dehydrate, QueryClient } from "@tanstack/react-query";
import { GetStaticPropsContext, NextPage } from "next";
import { useRouter } from "next/router";
import { fetchNewPaymentMethods } from "pages/apartments/services/queryFunctions";
import { fetchBanners } from "pages/app/services/queryFunctions";
import { BannerImageType, BannerPosition, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import $api from "services/RequestService";
import { UZ } from "utils/constants/locales";
import NextHead from "../src/common/next-head/NextHead";
import { queryKeys as appQueryKeys } from "../src/pages/app/utils/constants/queryKeys";
import Main from "../src/pages/main/Index";
import { fetchApartmentCounts, fetchHotFilters, fetchLatestAddedComplexes, fetchReadyComplexes, fetchTopBuilders, fetchTopComplexes, fetchTopMagazines } from "../src/pages/main/services/queryFunctions";
import { queryKeys } from "../src/pages/main/utils/constants/queryKeys";
import { Pagination } from "utils/models/pagination";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import { fetchFaqViewByType } from "pages/faq/services/queryFunction";
const Index: NextPage = props => {
  const {
    locale
  } = useRouter();
  const title = locale === UZ ? "O'zbekistondagi barcha novostroykalar haqida eng aktual ma'lumotlar" : "Купить квартиру в новостройке Узбекистана - актуальные цены на недвижимость от застройщика";
  const keyWords = locale === UZ ? "Toshkent kvartira, Toshkentda kvartira sotib olish, yangi uylar Toshkent, ko'chmas mulk Toshkent, ipoteka Toshkent, bo'lib to'lash Toshkent, Toshkent shahridagi uy-joylar, Toshkent markazidagi yangi uylar, Toshkent chekkasida yangi uylar, Toshkent novostroyka, Toshkent uylar narxi" : "купить квартиру в Ташкенте, новостройки Ташкент, недвижимость Ташкент, ипотека Ташкент, рассрочка Ташкент, квартиры в новостройках, жилье в Ташкенте, новостройки в центре Ташкента, новостройки на окраине Ташкента";
  return <>
      <NextHead title={title} keywords={keyWords} ogTitle={title} data-sentry-element="NextHead" data-sentry-source-file="index.tsx" />
      <Main banner={(props as {
      banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
      topComplexes: Pagination<NewResidentalComplexModel>;
    })?.banner} topComplexes={(props as {
      banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
      topComplexes: Pagination<NewResidentalComplexModel>;
    })?.topComplexes} latestAddedComplexes={(props as {
      banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
      topComplexes: Pagination<NewResidentalComplexModel>;
      latestAddedComplexes: Pagination<NewResidentalComplexModel>;
    })?.latestAddedComplexes} readyComplexes={(props as {
      banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
      topComplexes: Pagination<NewResidentalComplexModel>;
      latestAddedComplexes: Pagination<NewResidentalComplexModel>;
      readyComplexes: NewResidentalComplexModel[];
    })?.readyComplexes} data-sentry-element="Main" data-sentry-source-file="index.tsx" />
    </>;
};
export default Index;
export async function getStaticProps(context: GetStaticPropsContext) {
  const queryClient = new QueryClient();
  const banner = await fetchBanners({
    page_type: "main",
    lang: context.locale
  });
  const hotQueryKeys = [appQueryKeys.HOT_FILTERS];
  const {
    url,
    fetch
  } = fetchTopComplexes();
  const topComplexes = await fetch();
  const latestAddedComplexes = await fetchLatestAddedComplexes();
  const readyComplexes = await fetchReadyComplexes();
  await Promise.allSettled([queryClient.prefetchQuery([queryKeys.APARTMENT_COUNTS], fetchApartmentCounts), queryClient.prefetchQuery([queryKeys.TOP_BUILDERS], fetchTopBuilders), queryClient.prefetchQuery([queryKeys.NEW_TOP_MAGAZINES, context.locale], async () => fetchTopMagazines(context.locale)), queryClient.prefetchQuery([appQueryKeys.PAYMENT_METHODS_SELECT], fetchNewPaymentMethods), queryClient.prefetchQuery(hotQueryKeys, () => fetchHotFilters()), queryClient.prefetchQuery([queryKeys.MAIN_REGIONS], async () => {
    const res = await $api.get(`/city/select`);
    return res.data.data;
  }), queryClient.prefetchQuery([queryKeys.TOP_COMPLEXES, url], fetch), queryClient.prefetchQuery([appQueryKeys.METROS], async () => {
    const res = await $api.get(`/metro-line/select`);
    return res.data.data;
  }), queryClient.prefetchQuery([appQueryKeys.FAQ_VIEW_BY_TYPE, 'home'], () => fetchFaqViewByType('home')), queryClient.prefetchQuery([appQueryKeys.LATEST_ADDED_COMPLEXES], fetchLatestAddedComplexes), queryClient.prefetchQuery([appQueryKeys.READY_COMPLEXES], fetchReadyComplexes), queryClient.prefetchQuery([appQueryKeys.READY_COMPLEXES], fetchReadyComplexes), queryClient.prefetchQuery([queryKeys.REGION_DISTRICTS, 'gorod-tashkent'], async () => {
    const res = await $api.get(`/district/select?city_url_name=gorod-tashkent`);
    return res.data.data;
  })]);
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      messages: (await import(`../messages/main/${context.locale}.json`)).default,
      banner,
      topComplexes,
      latestAddedComplexes,
      readyComplexes
    },
    revalidate: 86400
  };
}