import React from 'react';
import { useRouter } from 'next/router';
import { cx } from 'utils/cx';
import { rootPaths } from 'utils/constants/rootPaths';
import { layoutLang } from 'utils/language/layout';
import { NewResidentalComplexModel } from 'utils/models/residentialComplexModel';
import PaginationNextIcon from 'assets/icons/PaginationNextIcon';
import ComplexCard from 'common/common-card/complex/ComplexCard';
import CustomLink from 'common/custom-link';
import MobileNewComplexes from '../new-complexes/mobile-new-complexes';
import styles from '../new-complexes/NewComplexes.module.scss';
import { DEFAULT_LOCATION } from 'utils/constants/defaultLocation';
type Props = {
  readyComplexes: NewResidentalComplexModel[];
};
const ReadyComplexes: React.FC<Props> = ({
  readyComplexes
}) => {
  const {
    locale
  } = useRouter();
  return <div className={styles.new_buildings} data-sentry-component="ReadyComplexes" data-sentry-source-file="index.tsx">
      <div className='wrapper'>
        <div className='flex items-center justify-between'>
          <h2 className={styles.title}>{layoutLang[String(locale)]['Tayyor binolar']}</h2>
          <div className={styles.all_link}>
            <CustomLink href={`/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}?complex_completed=true`} data-sentry-element="CustomLink" data-sentry-source-file="index.tsx">
              {layoutLang[String(locale)]['Hammasi']}
            </CustomLink>
            <PaginationNextIcon data-sentry-element="PaginationNextIcon" data-sentry-source-file="index.tsx" />
          </div>
        </div>
        <div className={cx(styles.body, 'desctop_only')}>
          {readyComplexes?.map(item => <ComplexCard data={item} key={item?.id} />)}
        </div>
        <div className="mobile_only">
          <MobileNewComplexes data={readyComplexes} type='ready_complexes' data-sentry-element="MobileNewComplexes" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </div>;
};
export default ReadyComplexes;