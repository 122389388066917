import React from "react";
import { MagazineType } from "../../pages/magazines/utils/models/magazineModel";
import { rootPaths } from "../../utils/constants/rootPaths";
import { makeUrl } from "../../utils/helpers/makeUrl";
import styles from "./MagazinesCont.module.scss";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { dayjsFormats } from "utils/constants/dayjsFormats";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/ru";
import "dayjs/locale/uz-latn";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Skeleton } from "antd";
import CustomLink from "common/custom-link";
import CustomImage from "common/custom-image";
import { RU, UZ } from "utils/constants/locales";
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
type Props = {
  magazines: MagazineType[] | undefined;
  loading?: boolean;
};
const MagazinesCont: React.FC<Props> = ({
  magazines,
  loading
}) => {
  const {
    locale
  } = useRouter();
  return <div className={styles.magazines_cont} data-sentry-component="MagazinesCont" data-sentry-source-file="MagazinesCont.tsx">
      {loading ? [1, 2, 3, 4, 5, 6, 7, 8]?.map((_, index) => <div className={styles.magazine} key={index}>
            <div className={styles.all}>
              <div className={styles.image} style={{
          width: "100%"
        }}>
                <Skeleton.Image active={true} />
              </div>
              <div className={`${styles.more_info} flex flex-col gap-2`}>
                <Skeleton.Input active={true} size={"large"} block />
                <Skeleton.Input active={true} size={"large"} block />
              </div>
            </div>
          </div>) : magazines?.map((magazine, index) => <CustomLink href={`${rootPaths.MAGAZINES}/${makeUrl(magazine.slug ? magazine.slug : magazine.name)}-${magazine.id}`} key={index} className={styles.magazine}>
            <div className={styles.all}>
              <div className={styles.tags}>
                <span>
                  {magazine.category?.name[locale as keyof typeof magazine.category.name]}
                </span>
              </div>
              <div className={styles.image}>
                <CustomImage src={magazine.image} alt={magazine.name} title={magazine?.name} />
              </div>
              <div className={styles.more_info}>
                <h3 className={styles.title}>{magazine.name}</h3>
                <span className={styles.date}>
                  {locale === UZ ? dayjs(magazine.created_time, dayjsFormats.DATE).locale("uz-latn").format("DD MMMM YYYY") : dayjs(magazine.created_time, dayjsFormats.DATE).locale(RU).format("DD MMMM YYYY")}
                </span>
              </div>
            </div>
          </CustomLink>)}
    </div>;
};
export default MagazinesCont;